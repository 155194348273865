import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";

const Navbar = () => {
    const navigate = useNavigate();
    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data !== 'err') {
                navigate('/')
            }
        })
    }

    useEffect(() => {
        $('[data-toggle="horizontal-menu-toggle"]').on("click", function () {
            $(".horizontal-menu .bottom-navbar").toggleClass("header-toggled");
        });

        if ($('.sidebar .sidebar-body').length) {
            new window.PerfectScrollbar('.sidebar-body');
        }

        $(document).on('click touchstart', function (e) {
            e.stopPropagation();
            if (!$(e.target).closest('.sidebar-toggler').length) {
                var sidebar = $(e.target).closest('.sidebar').length;
                var sidebarBody = $(e.target).closest('.sidebar-body').length;
                if (!sidebar && !sidebarBody) {
                    if ($('body').hasClass('sidebar-open')) {
                        $('body').removeClass('sidebar-open');
                    }
                }
            }
        });

        $(window).on('scroll', function () {
            if (window.matchMedia('(min-width: 992px)').matches) {
                var header = $('.horizontal-menu');
                if ($(window).scrollTop() >= 60) {
                    $(header).addClass('fixed-on-scroll');
                } else {
                    $(header).removeClass('fixed-on-scroll');
                }
            }
        })
        $('.sidebar .sidebar-body').on('hover', function () {
            $('body').addClass('overflow-hidden');
        }, function () {
            $('body').removeClass('overflow-hidden');
        });
    }, []);

    return (
        <nav className="navbar">
            <a href="/" className="sidebar-toggler" onClick={(e) => {
                e.preventDefault();
                $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                if (window.matchMedia('(min-width: 992px)').matches) {
                    $('body').toggleClass('sidebar-folded');
                } else if (window.matchMedia('(max-width: 991px)').matches) {
                    $('body').toggleClass('sidebar-open');
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
            </a>
            <div className="navbar-content">
                <ul className="navbar-nav link auth">
                    <li className="nav-item link">
                        <h5 className="mb-1 mb-md-0">نظام <span className="text-primary"> برادفورد </span>لإدارة المخزون بكفاءة</h5>
                    </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to="/users" className="text-dark ms-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><polyline points="17 11 19 13 23 9" /></svg>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href="/" className="text-dark ms-0" onClick={() => { logout(); return false; }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0" /><line x1={12} y1={2} x2={12} y2={12} /></svg>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;