import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import commaNumber from "comma-number";

const ClientBalance = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/client_balance');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable();
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">ميزان الحساب</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">تقارير الميزان</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>اسم الحساب</th>
                                        <th>نوع الحساب</th>
                                        <th>رصيد التمويلات</th>
                                        <th>مجموع من بیع</th>
                                        <th>مجموع من شراء</th>
                                        <th>رصيد الحساب</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {item.client_type !== 'شركة' ?
                                                    <Link to={`/profile/${item.client_id}`} className="text-decoration-none">{item.client_name}</Link>
                                                    : <Link to={`/funds/${item.client_id}`} className="text-decoration-none">{item.client_name}</Link>
                                                }
                                            </td>
                                            <td> <span className={`badge ${item.client_type === 'شركة' ? 'bg-success w-50' : item.client_type === 'مشتري' ? 'bg-light text-dark w-50' : 'bg-dark w-50'}`}>{item.client_type}</span> </td>
                                            <td>{commaNumber(item.fund_balance)} $</td>
                                            <td>{commaNumber(item.sales_amount)} $</td>
                                            <td>{commaNumber(item.purchases_amount)} $</td>
                                            <td><span className={item.credit >= 0 ? "text-success" : "text-danger"}>{commaNumber(item.credit)} $</span></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientBalance;