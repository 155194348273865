import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from "comma-number";
import Swal from "sweetalert2";

const Sales = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/sale');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true,
                        })
                    }

                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const [client, setClient] = useState([]);
    const getClient = async () => {
        const { data } = await axios.get('/buyer');
        setClient(
            data.map((item) => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            })
        );
    }

    const [feed, setfeed] = useState([]);
    const getfeed = async () => {
        const { data } = await axios.get('/store_balance/2');
        setfeed(
            data.map((item) => {
                return {
                    value: item.feed_id,
                    label: `${item.feed_name} - كمية الموجودة: ${commaNumber(item.balance_qty)} كغ`,
                    balance: item.balance_qty,
                };
            })
        );
    }

    useEffect(() => {
        getData();
        getfeed();
        getClient();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [current, setcurrent] = useState([]);
    const [balance, setbalance] = useState(0);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">المبيعات</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">قائمة المبيعات</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">بيع المواد</span>
                                    </button>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>الــــی</th>
                                        <th>نوع الحمل</th>
                                        <th>السعر</th>
                                        <th>الكمية</th>
                                        <th>مجموع المبلغ</th>
                                        <th>اسم السائق</th>
                                        <th>رقم السيارة</th>
                                        <th>الملاحظة</th>
                                        <th>التاريخ</th>
                                        <th>الخيارات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td> <Link to={`/profile/${item.client_id}`} className="text-decoration-none">{item.client_name}</Link> </td>
                                            <td>{item.feed_name}</td>
                                            <td>{commaNumber(item.price)} $</td>
                                            <td>{commaNumber(item.qty)} كغ</td>
                                            <td>{commaNumber(item.total_price)} $</td>
                                            <td>{item.driver_name}</td>
                                            <td>{item.plate_number}</td>
                                            <td>{item.note ? item.note : "-"}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target="#edit" onClick={() => setcurrent(data[index])}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                    Swal.fire({
                                                        title: 'تحذير',
                                                        text: "هل انت متاكد من حذف هذا العنصر ؟",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: 'نعم',
                                                        cancelButtonText: 'لا',
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            axios.delete(`/sale/${item.id}`).then(() => {
                                                                getData();
                                                            })
                                                        }
                                                    })
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title"> بيع جديد</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                        </div>
                                        <form className="form-sample" onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = new FormData(e.target);
                                            const data = Object.fromEntries(formData);
                                            axios.post(`/sale`, data).then(res => {
                                                if (isNaN(res.data)) {
                                                    toast.error(res.data, {
                                                        position: "top-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                } else {
                                                    getData();
                                                    getfeed();
                                                    toast.success('تمت الاضافة بنجاح');
                                                    e.target.reset();
                                                    window.$('#new').modal('hide');
                                                }
                                            });
                                        }}>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="client_id" className="form-label">الحساب</label>
                                                            <Select options={client} name="client_id" placeholder="اختر الحساب" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="feed_id" className="form-label">المادة</label>
                                                            <Select options={feed} name="feed_id" placeholder="اختر المادة" required onChange={(e) => { setbalance(e.balance) }} />
                                                            <small className="text-muted">كمية الموجودة <span>{commaNumber(balance)}</span> كغ</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="qty" className="form-label">الكمية</label>
                                                            <input type="number" className="form-control" step={"any"} name="qty" placeholder="الكمية" required onChange={(e) => {
                                                                if (e.target.value > balance) {
                                                                    toast.error('الكمية المطلوبة اكبر من الكمية المتاحة', {
                                                                        position: "top-right",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                    let x = e.target.value - balance
                                                                    e.target.value = e.target.value - x
                                                                }
                                                            }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="price" className="form-label">السعر (كيلو)</label>
                                                            <input type="number" className="form-control" step={"any"} name="price" placeholder="السعر" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_name" className="form-label"> اسم السائق</label>
                                                            <input type="text" className="form-control" name="driver_name" placeholder=" اسم السائق" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="plate_number" className="form-label"> رقم السيارة</label>
                                                            <input type="text" className="form-control" name="plate_number" placeholder=" رقم السيارة" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="note" className="form-label">ملاحظة</label>
                                                            <textarea className="form-control" name="note" placeholder="ملاحظة" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                <button type="submit" className="btn btn-primary"> اضافة</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="edit" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">تعديل</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = new FormData(e.target);
                                            const data = Object.fromEntries(formData);
                                            axios.put(`/sale/${current.id}`, data).then(res => {
                                                if (isNaN(res.data)) {
                                                    toast.error(res.data, {
                                                        position: "top-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                } else {
                                                    getData();
                                                    getfeed();
                                                    e.target.reset();
                                                    toast.success('تم تعديل بنجاح');
                                                    window.$("#edit").modal('hide');
                                                }
                                            });
                                        }}>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="client_id" className="form-label">الحساب</label>
                                                            <Select options={client} name="client_id" placeholder="اختر الحساب" value={client.find(option => option.value === current.client_id)} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="feed_id" className="form-label">المادة</label>
                                                            <Select options={feed} name="feed_id" placeholder="اختر المادة" value={feed.find(option => option.value === current.feed_id)} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="qty" className="form-label">الكمية</label>
                                                            <input type="number" className="form-control" step={"any"} name="qty" placeholder="الكمية" defaultValue={current.qty} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="price" className="form-label">السعر (كيلو)</label>
                                                            <input type="number" className="form-control" step={"any"} name="price" placeholder="السعر" defaultValue={current.price} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="driver_name" className="form-label"> اسم السائق</label>
                                                            <input type="text" className="form-control" name="driver_name" placeholder=" اسم السائق" defaultValue={current.driver_name} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="plate_number" className="form-label"> رقم السيارة</label>
                                                            <input type="text" className="form-control" name="plate_number" placeholder=" رقم السيارة" defaultValue={current.plate_number} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="note" className="form-label">ملاحظة</label>
                                                            <textarea className="form-control" name="note" placeholder="ملاحظة" defaultValue={current.note} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                <button type="submit" className="btn btn-primary"> تحديث</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Sales;