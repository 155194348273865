import { useEffect, useState } from "react";
import axios from "axios";
import commaNumber from "comma-number";
import { useParams } from "react-router-dom";

const Profile = () => {
    const id = useParams().id;
    const [profile, setProfile] = useState([]);
    const [baalance, setbalance] = useState([]);
    const [transaction, settransaction] = useState([]);
    const [fund, setfund] = useState([]);
    const getProfile = async () => {
        const { data } = await axios.get(`/client/${id}`);
        setProfile(data)

        const { data: balance } = await axios.get(`/balance/${id}`);
        setbalance(balance)

        const { data: trx } = await axios.get(`/transaction/${id}?t=${data.client_type}`);
        if (data.client_type === 'تاجر') {
            settransaction(
                <>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>نوع</th>
                            <th>السعر</th>
                            <th>الكمية</th>
                            <th>سعر الصرف</th>
                            <th>مجموع المبلغ</th>
                            <th>الملاحظة</th>
                            <th>التاريخ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trx.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.feed_name}</td>
                                <td>{commaNumber(parseInt(item.price))} $</td>
                                <td>{commaNumber(item.qty)} كغ</td>
                                <td>
                                    <div>1 $</div>
                                    <div>{commaNumber(item.rob_rate)} ₽</div>
                                    <div>{commaNumber(item.iqd_rate)} ع.د</div>
                                </td>
                                <td>
                                    <div>{commaNumber(item.usd_price)} $</div>
                                    <div>{commaNumber(item.rob_price)} ₽</div>
                                    <div>{commaNumber(item.iqd_price)} ع.د</div>
                                </td>
                                <td>{item.note ? item.note : "-"}</td>
                                <td>{new Date(item.created).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </>
            )
        } else if (data.client_type === 'مشتري') {
            settransaction(
                <>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>نوع الحمل</th>
                            <th>السعر</th>
                            <th>الكمية</th>
                            <th>مجموع المبلغ</th>
                            <th>اسم السائق</th>
                            <th>رقم السيارة</th>
                            <th>الملاحظة</th>
                            <th>التاريخ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trx.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.feed_name}</td>
                                <td>{commaNumber(item.price)} $</td>
                                <td>{commaNumber(item.qty)} كغ</td>
                                <td>{commaNumber(item.total_price)} $</td>
                                <td>{item.driver_name}</td>
                                <td>{item.plate_number}</td>
                                <td>{item.note ? item.note : "-"}</td>
                                <td>{new Date(item.created).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </>
            )
        }
        if (trx.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl-trx')) {
                        window.$('.dt-tbl-trx').DataTable();
                    } else {
                        window.$('.dt-tbl-trx').DataTable();
                    }
                }
            });
        }

        const { data: fund } = await axios.get(`/fund/${id}`);
        setfund(fund)
        if (fund.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl-fund')) {
                        window.$('.dt-tbl-fund').DataTable();
                    } else {
                        window.$('.dt-tbl-fund').DataTable();
                    }
                }
            });
        }
    }
    useEffect(() => {
        getProfile();
        // eslint-disable-next-line
    }, [id]);

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-md-12 mb-5">
                    <div className="card">
                        <div className="card-body bg-light">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">معلومات الحساب</h6>
                                </div>
                            </div>
                            <div className="mb-2">
                                <span className="text-muted">نوع: </span>
                                <span>{profile.client_type}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-muted">الاسم: </span>
                                <span>{profile.full_name}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-muted">رقم الهاتف: </span>
                                <span>{profile.phone_no}</span>
                            </div>
                            <div className="mb-2">
                                <span className="text-muted">العنوان: </span>
                                <span>{profile.address}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row mb-4">
                        <div className="col-md-4 text-center">
                            <div className="card bg-warning">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="card-title mb-0"> ميزان الصندوق</h6>
                                    </div>
                                    <h4 className="mb-2 m-1">{commaNumber(parseInt(baalance.fund_balance))} $</h4>
                                    <div className="text-center">
                                        <p className="text-white"><span>ميزان الاموال من شحن و سحب</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card bg-primary">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="card-title mb-0">مبلغ الشحنات</h6>
                                    </div>
                                    <h4 className="mb-2 m-1">{baalance.client_type === 'تاجر' ? commaNumber(baalance.sales_amount) : commaNumber(baalance.purchases_amount)} $</h4>
                                    <div className="text-center">
                                        <p className="text-white"><span>اجمالي المبلغ المعاملات</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card bg-success">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="card-title mb-0">رصيد الحساب</h6>
                                    </div>
                                    <h4 className="mb-2 m-1">{commaNumber(baalance.credit)} $</h4>
                                    <div className="text-center">
                                        <p className="text-white"><span> ميزان الحساب</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h6 className="card-title">تقارير الشحنات</h6>
                                        </div>
                                    </div>
                                    <table className="table table-sm dt-tbl-trx" style={{ width: "100%" }}>
                                        {transaction}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h6 className="card-title">قائمة تمويلات</h6>
                                        </div>
                                    </div>
                                    <table className="table table-sm dt-tbl-fund" style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>المبلغ</th>
                                                <th>العملية</th>
                                                <th>ب دولار</th>
                                                <th>ملاحظة</th>
                                                <th>التاريخ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fund.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{commaNumber(item.amount)} {item.currency}</td>
                                                        <td>{item.method === "شحن" ? (<span className="badge bg-primary">{item.method}</span>) : (<span className="badge bg-danger">{item.method}</span>)}</td>
                                                        <td>{commaNumber(item.amount_usd)} $</td>
                                                        <td>{item.note}</td>
                                                        <td>{new Date(item.created).toLocaleDateString()}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Profile;