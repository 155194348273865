import { useEffect, useState } from "react";
import axios from 'axios';
import commaNumber from "comma-number";

const Dashboard = () => {
  const [stats, setstats] = useState([]);
  const getstats = async () => {
    const { data } = await axios.get('/stats');
    setstats(data)
  }

  const [data, setData] = useState([]);
  const [local, setlocal] = useState([]);
  const getData = async () => {
    const { data } = await axios.get('/global_store');
    setData(data);
    const { data: local } = await axios.get('/local_store');
    setlocal(local)
  }

  useEffect(() => {
    getstats();
    getData();
  }, [])

  return (
    <div className="page-content">
      <div className="row mb-3">
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">كمیة المشتريات</h6>
              </div>
              <h4 className="mb-2 m-1"> {commaNumber(stats.orderStats?.qty ?? 0)} كغ </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">مبلغ المشتريات</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.orderStats?.usd_price ?? 0)} $</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">كمیة المباع</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.salesStats?.qty)} كغ</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">مبلغ المباع</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.salesStats?.total_price)} $</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">مواد التالفة</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.spillageQtySum)} كغ</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">اجمالي الربح</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.viewProfitSum)} $</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">رصيد الشرکة</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.companyCredit)} $</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center mb-3">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h6 className="card-title mb-0">رصيد الوکلاء</h6>
              </div>
              <h4 className="mb-2 m-1">{commaNumber(stats.customerCredit)} $</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h6 className="card-title">موجودات من مخزن روسيا</h6>
                </div>
              </div>
              <table className="table table-borderless table-sm">
                <thead>
                  <tr>
                    <th>نوع</th>
                    <th>كمية الموجودة</th>
                  </tr>
                </thead>
                <tbody>
                  {local.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item.feed_name}</td>
                      <td>{commaNumber(item.balance_qty)} كغ</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h6 className="card-title">موجودات من مخزن جمبور</h6>
                </div>
              </div>
              <table className="table table-borderless table-sm">
                <thead>
                  <tr>
                    <th>نوع</th>
                    <th>كمية الموجودة</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item.feed_name}</td>
                      <td>{commaNumber(item.spoilage_qty)} كغ</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
