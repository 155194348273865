import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const Clients = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/client');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable();
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [current, setcurrent] = useState([]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">حسابات</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">قائمة الحسابات</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy={7} r={4} /><line x1={20} y1={8} x2={20} y2={14} /><line x1={23} y1={11} x2={17} y2={11} /></svg>
                                        <span className="ms-2">اضافة حساب</span>
                                    </button>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>نوع</th>
                                        <th>اسم الحساب</th>
                                        <th>ر.الهاتف</th>
                                        <th>العنوان</th>
                                        <th>تاريخ التسجيل</th>
                                        <th>الخيارات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td> <span className={`badge ${item.client_type === 'شركة' ? 'bg-success w-50' : item.client_type === 'مشتري' ? 'bg-light text-dark w-50' : 'bg-dark w-50'}`}>{item.client_type}</span> </td>
                                            <td> <Link to={`/profile/${item.id}`} className="text-decoration-none">{item.full_name}  </Link> </td>
                                            <td>{item.phone_no}</td>
                                            <td>{item.address}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target="#edit" onClick={() => setcurrent(data[index])}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                {item.client_type !== 'شركة' &&
                                                    <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                        Swal.fire({
                                                            title: 'تحذير',
                                                            text: "هل انت متاكد سوف يتم الحذف جميع البيانات المتعلعفة بهذا الحساب بما في (تمويلات, مشتريات, مبيعات) ؟",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'نعم',
                                                            cancelButtonText: 'لا',
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                axios.delete(`/client/${item.id}`).then(() => {
                                                                    getData();
                                                                    toast.success('تم حذف الحساب والبيانات ذات الصلة بنجاح');
                                                                });
                                                            }
                                                        });
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title"> حساب جديد</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                        </div>
                                        <form className="form-sample" onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = new FormData(e.target);
                                            const data = Object.fromEntries(formData);
                                            axios.post(`/client`, data).then(res => {
                                                if (isNaN(res.data)) {
                                                    toast.error(res.data, {
                                                        position: "top-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                } else {
                                                    getData();
                                                    e.target.reset();
                                                    window.$('#new').modal('hide');
                                                }
                                            });
                                        }}>
                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">نوع الحساب</label>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="client_type" id="inlineRadio1" defaultValue="تاجر" defaultChecked />
                                                        <label className="form-check-label" htmlFor="inlineRadio1">تاجر</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="client_type" id="inlineRadio2" defaultValue="مشتري" />
                                                        <label className="form-check-label" htmlFor="inlineRadio2">مشتري</label>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="name" className="form-label">اسم كامل</label>
                                                    <input type="text" className="form-control" name="full_name" placeholder="اكتب اسم" required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="phone_no" className="form-label">ر.هاتف</label>
                                                    <input type="text" className="form-control" name="phone_no" placeholder="اكتب رقم الهاتف" required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="address" className="form-label">عنوان</label>
                                                    <input type="text" className="form-control" name="address" placeholder="عنوان الحساب" required />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                <button type="submit" className="btn btn-primary"> اضافة</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="edit" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">تعديل معلومات</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = new FormData(e.target);
                                            const data = Object.fromEntries(formData);
                                            axios.put(`/client/${current.id}`, data).then(res => {
                                                if (isNaN(res.data)) {
                                                    toast.error(res.data, {
                                                        position: "top-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                } else {
                                                    e.target.reset();
                                                    getData();
                                                    window.$("#edit").modal('hide');
                                                }
                                            });
                                        }}>

                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">نوع الحساب</label>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="client_type" id="inlineRadio1" defaultValue="تاجر" defaultChecked={current.client_type === 'تاجر'} />
                                                        <label className="form-check-label" htmlFor="inlineRadio1">تاجر</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="client_type" id="inlineRadio2" defaultValue="مشتري" defaultChecked={current.client_type === 'مشتري'} />
                                                        <label className="form-check-label" htmlFor="inlineRadio2">مشتري</label>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="name" className="form-label">اسم كامل</label>
                                                    <input type="text" className="form-control" name="full_name" defaultValue={current.full_name} placeholder="اكتب اسم" required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="phone_no" className="form-label">ر.هاتف</label>
                                                    <input type="text" className="form-control" name="phone_no" defaultValue={current.phone_no} placeholder="اكتب رقم الهاتف" required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="address" className="form-label">عنوان</label>
                                                    <input type="text" className="form-control" name="address" defaultValue={current.address} placeholder="عنوان الحساب" required />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">اغلاق</button>
                                                <button type="submit" className="btn btn-primary"> تحديث</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
