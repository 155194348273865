import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


const FundBy = () => {
    const id = useParams().id
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/fund/${id}`);
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable();
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }
    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item"><Link to="/fund">تمويلات</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{data.length > 0 && data[0].client_name}</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">قائمة تمويلات: {data.length > 0 && data[0].client_name}</h6>
                                </div>
                            </div>
                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>المبلغ</th>
                                        <th>العملية</th>
                                        <th>سعر الروبل</th>
                                        <th>سعر الدینار</th>
                                        <th>المبلغ بالدولار</th>
                                        <th>ملاحظة</th>
                                        <th>التاريخ</th>
                                        <th>الاجراء</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{commaNumber(item.amount)} {item.currency}</td>
                                            <td>{item.method === "شحن" ? (<span className="badge bg-primary">{item.method}</span>) : (<span className="badge bg-danger">{item.method}</span>)}</td>
                                            <td>{commaNumber(item.rob_rate)} ₽</td>
                                            <td>{commaNumber(item.iqd_rate)} ع.د</td>
                                            <td>{commaNumber(item.amount_usd)} $</td>
                                            <td>{item.note ? item.note : '-'}</td>
                                            <td>{new Date(item.created).toLocaleDateString()}</td>
                                            <td>
                                                <span type="button" className="badge bg-warning mx-1" data-bs-toggle="modal" data-bs-target={`#update${item.id}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                </span>
                                                <span type="button" className="badge bg-danger mt-1" onClick={() => {
                                                    Swal.fire({
                                                        title: 'تحذير',
                                                        text: "هل انت متاكد من حذف هذا العنصر ؟",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#3085d6',
                                                        cancelButtonColor: '#d33',
                                                        confirmButtonText: 'نعم',
                                                        cancelButtonText: 'لا',
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            axios.delete(`/fund/${item.id}`).then(() => {
                                                                getData();
                                                            })
                                                        }
                                                    })
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                </span>
                                            </td>
                                            <div className="modal fade" id={`update${item.id}`} tabIndex={-1} aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title"> تحديث المعلومات</h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="fund-content" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.put(`/fund/${item.id}`, data).then(res => {
                                                                if (isNaN(res.data)) {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    toast.success("تم التحديث بنجاح");
                                                                    window.$(`#update${item.id}`).modal('hide');
                                                                    setData([]);
                                                                    getData();
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="method" className="form-label">نوع العملية</label>
                                                                            <select className="form-select" name="method" required>
                                                                                {item.method === "شحن" ? (
                                                                                    <>
                                                                                        <option value="شحن">شحن</option>
                                                                                        <option value="سحب">سحب</option>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <option value="سحب">سحب</option>
                                                                                        <option value="شحن">شحن</option>
                                                                                    </>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="currency" className="form-label">العملة</label>
                                                                            <select className="form-select" name="currency" required>
                                                                                {item.currency === "د.ع" ? (
                                                                                    <>
                                                                                        <option value="د.ع">د.ع</option>
                                                                                        <option value="$">$</option>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <option value="$">$</option>
                                                                                        <option value="د.ع">د.ع</option>
                                                                                    </>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="amount" className="form-label">سعر روبل</label>
                                                                            <input type="number" className="form-control" name="rob_rate" step={"any"} placeholder="اكتب سعر الخالي ل روبل مقابل 1 دولار" defaultValue={item.rob_rate} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="amount" className="form-label">سعر دينار</label>
                                                                            <input type="number" className="form-control" name="iqd_rate" step={"any"} placeholder="اكتب سعر الخالي ل دينار مقابل 1 دولار" defaultValue={item.iqd_rate} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="created" className="form-label">التاريخ</label>
                                                                            <input type="date" className="form-control" name="created" defaultValue={new Date(item.created).toISOString().slice(0, 10)} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="note" className="form-label">ملاحظة</label>
                                                                            <textarea className="form-control" name="note" placeholder="اكتب الملاحظة" defaultValue={item.note} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"> اغلاق</button>
                                                                <button type="submit" className="btn btn-primary"> تحدث المعلومات</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FundBy;