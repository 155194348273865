import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from 'react-select'
import commaNumber from "comma-number";

const Fund = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/funds_summary');
        setData(data);
        if (data.length > 0) {
            axios.get("/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable();
                    }
                }
            });
            $('.py-4').hide();
            $('.dt-tbl').show();
        } else {
            $('.py-4').hide();
            $('.dt-tbl').show();
        }
    }

    const [client, setClient] = useState([]);
    const [bradford, setbradford] = useState([]);
    const getClient = async () => {
        const { data } = await axios.get('/client');
        setClient(
            data.map((item) => {
                return {
                    value: item.id,
                    label: item.full_name,
                };
            }),
        );

        const { data: bradford } = await axios.get('/bradford');
        setbradford(bradford)
    }

    useEffect(() => {
        getData();
        getClient();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [current, setcurrent] = useState([]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">الرئيسية</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">تمويلات</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">قائمة التمويلات</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#transaction">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-credit-card"><rect x={1} y={4} width={22} height={16} rx={2} ry={2} /><line x1={1} y1={10} x2={23} y2={10} /></svg>
                                        <span className="ms-2">تمويل</span>
                                    </button>
                                </div>
                            </div>

                            <div className="container py-4">
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>
                                                <div className="skeleton_wave" />
                                            </th>
                                            <th>
                                                <div className="skeleton_wave" /> </th>
                                            <th><div className="skeleton_wave" /></th>
                                            <th><div className="skeleton_wave" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><div className="skeleton_wave rounded" /></th>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                            <td><div className="skeleton_wave rounded" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table className="table table-sm dt-tbl" style={{ width: "100%", display: "none" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>اسم الحساب</th>
                                        <th>مجموع الشحن</th>
                                        <th>مجموع السحب</th>
                                        <th>رصید الحساب</th>
                                        <th>اجراءات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {item.client_type !== 'شركة' ?
                                                    <Link to={`/profile/${item.client_id}`} className="text-decoration-none">{item.client_name}</Link>
                                                    :
                                                    <Link to="/funds-log" className="text-decoration-none">{item.client_name}</Link>
                                                }
                                            </td>
                                            <td>{commaNumber(item.charge)} $</td>
                                            <td>{commaNumber(item.withdrawal)} $</td>
                                            <td><span className={item.balance >= 0 ? "text-success" : "text-danger"}>{commaNumber(item.balance)} $</span> </td>
                                            <td>
                                                <span type='button' className="badge bg-success mx-2" data-bs-toggle="modal" data-bs-target="#fund" onClick={() => setcurrent(data[index])}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-dollar-sign"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" /></svg>
                                                </span>
                                                <Link to={`/funds/${item.client_id}`} className="badge bg-primary" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-repeat"><polyline points="17 1 21 5 17 9" /><path d="M3 11V9a4 4 0 0 1 4-4h14" /><polyline points="7 23 3 19 7 15" /><path d="M21 13v2a4 4 0 0 1-4 4H3" /></svg>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div className="modal fade" id="transaction" tabIndex={-1} aria-labelledby="transaction" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">عملية جديد</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <form className="fund-content" onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = new FormData(e.target);
                                            const data = Object.fromEntries(formData);
                                            axios.post(`/fund`, data).then(res => {
                                                if (isNaN(res.data)) {
                                                    toast.error(res.data, {
                                                        position: "top-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                } else {
                                                    toast.success("تم العملية بنجاح");
                                                    window.$("#transaction").modal('hide');
                                                    getData();
                                                    e.target.reset();
                                                }
                                            });
                                        }}>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="client_id" className="form-label">الحساب</label>
                                                            <Select options={client} name="client_id" placeholder="اختر الحساب" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="method" className="form-label">نوع العملية</label>
                                                            <select className="form-select" name="method" required>
                                                                <option value="شحن">شحن</option>
                                                                <option value="سحب">سحب</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="currency" className="form-label">نوع العملة</label>
                                                            <select className="form-select" name="currency" required>
                                                                <option value="$">$</option>
                                                                <option value="₽">₽</option>
                                                                <option value="ع.د">ع.د</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="amount" className="form-label">المبلغ</label>
                                                            <input type="number" className="form-control" step='any' name="amount" placeholder="اكتب المبلغ" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="note" className="form-label">ملاحظة</label>
                                                            <textarea className="form-control" name="note" placeholder="اكتب الملاحظة" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="created" className="form-label">التاريخ</label>
                                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().slice(0, 10)} required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">الغاء</button>
                                                    <button type="submit" className="btn btn-success">تقديم</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="fund" tabIndex={-1} aria-labelledby="fund" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">عملية حوالة</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <form className="fund-content" onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = new FormData(e.target);
                                            const data = Object.fromEntries(formData);
                                            axios.post(`/fund`, data).then(res => {
                                                if (isNaN(res.data)) {
                                                    toast.error(res.data, {
                                                        position: "top-left",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                } else {
                                                    toast.success("تم العملية بنجاح");
                                                    window.$("#fund").modal('hide');
                                                    getData();
                                                    getClient();
                                                    e.target.reset();
                                                }
                                            });
                                        }}>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="client_id" className="form-label">علی حساب</label>
                                                            <Select options={client} name="client_id" value={client.find(option => option.value === current.client_id)} required disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="method" className="form-label">نوع العملية</label>
                                                            <select className="form-select" name="method" required>
                                                                <option value="شحن">شحن</option>
                                                                <option value="سحب">سحب</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="currency" className="form-label">نوع العملة</label>
                                                            <select className="form-select" name="currency" required>
                                                                <option value="$">$</option>
                                                                <option value="₽">₽</option>
                                                                <option value="ع.د">ع.د</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="amount" className="form-label">المبلغ</label>
                                                            <input type="number" className="form-control" step='any' name="amount" placeholder="اكتب المبلغ" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="created" className="form-label">التاريخ</label>
                                                            <input type="date" className="form-control" name="created" defaultValue={new Date().toISOString().slice(0, 10)} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="note" className="form-label">ملاحظة</label>
                                                            <textarea className="form-control" name="note" placeholder="اكتب الملاحظة" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">الغاء</button>
                                                    <button type="submit" className="btn btn-success" >تقديم</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-4">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col text-center">
                                            <h5 className="card-title text-uppercase text-muted mb-1">رصید الشركة</h5>
                                            <span className="h4 font-weight-bold mb-0"> {commaNumber(bradford.balance)}$</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Fund;