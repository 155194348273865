import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";

const Sidebar = () => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (res.data === "err") {
                navigate("/");
            }
        });
    }, [navigate]);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/dashboard" className="sidebar-brand">
                    Brad<span>Ford</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").hover(function () {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, function () {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item nav-category">الرئيسية</li>
                    <li className="nav-item">
                        <Link to="/dashboard" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2" /><polyline points="2 17 12 22 22 17" /><polyline points="2 12 12 17 22 12" /></svg>
                            <span className="link-title"> احصائيات</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/clients" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx={9} cy={7} r={4} /><path d="M23 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                            <span className="link-title"> حسابات</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/feed" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-slack"><path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" /><path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" /><path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" /><path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" /><path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" /><path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" /><path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" /><path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>
                            <span className="link-title"> المواد</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">المالية</li>
                    <li className="nav-item">
                        <Link to="/funds" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" /></svg>
                            <span className="link-title"> تمويل</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/revenu" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-trending-up"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18" /><polyline points="17 6 23 6 23 12" /></svg>
                            <span className="link-title">إيرادات</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/client-balance" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12" /></svg>
                            <span className="link-title">ميزان</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/rate" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-dollar-sign"><line x1={12} y1={1} x2={12} y2={23} /><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" /></svg>
                            <span className="link-title">سعر الیوم</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">عملیات</li>
                    <li className="nav-item auth">
                        <Link to="/global" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1={12} y1={15} x2={12} y2={3} /></svg>
                            <span className="link-title">المشتريات</span>
                        </Link>
                    </li>
                    <li className="nav-item auth">
                        <Link to="/local" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-truck"><rect x={1} y={3} width={15} height={13} /><polygon points="16 8 20 8 23 11 23 16 16 16 16 8" /><circle cx="5.5" cy="18.5" r="2.5" /><circle cx="18.5" cy="18.5" r="2.5" /></svg>
                            <span className="link-title">الشحنات</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/sales" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-shopping-cart"><circle cx={9} cy={21} r={1} /><circle cx={20} cy={21} r={1} /><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
                            <span className="link-title">المبيعات</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/spoilage" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-trash-2"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /></svg>
                            <span className="link-title">التالفات</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">المخازن</li>
                    <li className="nav-item">
                        <Link to="/global-store" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-database"><ellipse cx={12} cy={5} rx={9} ry={3} /><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3" /><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5" /></svg>
                            <span className="link-title">خارجي</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/local-store" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
                            <span className="link-title">داخلي</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;