import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from './Components/Pages/404';
import Login from './Components/Pages/Login';
import Dashboard from './Components/Pages/Dashboard';
import MainParts from './Components/Layout/MainParts';
import Feed from './Components/Pages/Feed';
import { Clients } from './Components/Accounts/Clients';
import Global from './Components/Orders/Global';
import Local from './Components/Orders/Local';
import Sales from './Components/Sales/Sales';
import Spoilage from './Components/Spoilage/Spoilage';
import SpoilageBy from './Components/Spoilage/SpoilageBy';
import Fund from './Components/Fund/Fund';
import ClientBalance from './Components/Reports/ClientBalance';
import FundBy from './Components/Fund/FundBy';
import GlobalStore from './Components/Reports/GlobalStore';
import LocalStore from './Components/Reports/Localstore';
import Profile from './Components/Accounts/Profile';
import FeedProfit from './Components/Reports/FeedProfit';
import FundLog from './Components/Fund/FundLog';
import Users from './Components/Accounts/Users';
import Rate from './Components/Pages/Rate';

const AppRoutes = createBrowserRouter(
    [
        {
            path: "/",
            element: <Login />,
            errorElement: <ErrorPage />
        },
        {
            path: "/dashboard",
            element: <MainParts><Dashboard /></MainParts>,
            errorElement: <ErrorPage />
        },
        {
            path: "/feed",
            element: <MainParts><Feed /></MainParts>,
            errorElement: <ErrorPage />
        },
        {
            path: "/clients",
            element: <MainParts><Clients /></MainParts>,
            errorElement: <ErrorPage />
        },
        {
            path: "/global",
            element: <MainParts><Global /></MainParts>,
            errorElement: <ErrorPage />
        },
        {
            path: "/local",
            element: <MainParts><Local /></MainParts>,
            errorElement: <ErrorPage />
        },
        {
            path: "/sales",
            element: <MainParts><Sales /></MainParts>,
            errorElement: <ErrorPage />
        },
        {
            path: "/spoilage",
            element: <MainParts><Spoilage /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/spoilage/:id",
            element: <MainParts><SpoilageBy /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/funds",
            element: <MainParts><Fund /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/funds/:id",
            element: <MainParts><FundBy /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/funds-log",
            element: <MainParts><FundLog /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/client-balance",
            element: <MainParts><ClientBalance /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/global-store",
            element: <MainParts><GlobalStore /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/local-store",
            element: <MainParts><LocalStore /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/profile/:id",
            element: <MainParts><Profile /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/revenu",
            element: <MainParts><FeedProfit /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/users",
            element: <MainParts><Users /></MainParts>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/rate",
            element: <MainParts><Rate /></MainParts>,
            errorElement: <ErrorPage />,
        },

    ]
);

export default AppRoutes;
